import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgClass, NgFor, NgIf} from '@angular/common';

@Component({
    selector: 'steps-indicator',
    templateUrl: './steps-indicator.component.html',
    styleUrls: ['./steps-indicator.component.css'],
    standalone: true,
    imports: [NgIf, NgFor, NgClass]
})
export class StepsIndicatorComponent implements OnInit{

    @Input() totalCount: number;
    @Input() selectedIndex: number;
    @Input() customSteps: boolean = false;
    @Input() stepTitles = [];
    @Input() disabled : boolean = false;

    @Output() onSelection: EventEmitter<number>;

    indicators: Array<any>;
    customIndicators : {id: number, step: number, title: string}[] = [];

    constructor() {
        this.onSelection = new EventEmitter<number>();
    }

    ngOnInit() {
        this.setIndicators();
    }


    setIndicators = () => {
        if(this.customSteps) {
           for (let i = 0; i < this.totalCount; i++) {
               const step = {
                   id: i,
                   step: i+1,
                   title : ''
               }
               if(this.stepTitles.length > i) {
                   step['title'] = this.stepTitles[i]
               }
               this.customIndicators.push(step)
           }
        }else{
            this.indicators = new Array(this.totalCount).fill(0);
        }

    }

    onIndicatorClicked = (index: number) => {
        if(!this.disabled) {
            this.onSelection.emit(index);
        }
    }

}
