<div class="steps-indicator-component">

    <div  class="custom-indicators-container" *ngIf="customSteps">
        <div
                class="custom-indicator-segment"
                *ngFor="let indicator of customIndicators; index as i"
                (click)="onIndicatorClicked(i)">
            <div class="custom-indicator"
                 [ngClass]="{
                 'active': (selectedIndex === i && !disabled) || (disabled &&  selectedIndex === i && (i === 0 || i === customIndicators.length - 1) )
                 }">
                {{indicator.step}}
            </div>
            <div *ngIf="selectedIndex === i && indicator.title !== ''" class="indicator-title">{{indicator.title}}</div>
        </div>
    </div>


    <div class="indicators-container" *ngIf="!customSteps">
            <div
            *ngFor="let indicator of indicators; index as i" 
            class="indicator"
            [ngClass]="{ 'active': selectedIndex === i }"
            (click)="onIndicatorClicked(i)">
            </div>
    </div>
</div>
